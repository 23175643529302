@font-face {
  font-family: Avenir;
  src: url(../../public/fonts/AvenirNextLTPro-Regular.otf);
}

body {
  padding: 0;
  margin: 0;
  /* color: #576981; */
  font-family: Avenir;
}

.form-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.form-separator::before {
  content: "";
  width: 125px;
  height: 1px;
  background: rgba(6, 41, 66, 0.2);
  margin-right: 5px;
  padding-left: 0px;
}
.form-separator::after {
  content: "";
  width: 125px;
  height: 1px;
  background: rgba(6, 41, 66, 0.2);
  margin-left: 5px;
  padding-right: 0px;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  font-size: 25px;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-link.active {
  background-color: #eee;
}

.avatar {
  width: 50px;
  height: 50px;
  border: 5px solid #ddd;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 25px;
}

.customer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.customer h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  /* padding: 0 5px; */
}
.customer p {
  font-size: 13px;
  margin: 0;
}

p {
  margin: 0;
}
